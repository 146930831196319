.message-log {
    overflow: scroll;
    min-height: 4rem;
    max-height: 12rem;
    padding: 0;
    margin: 0.5rem;

    width: 30rem;
    resize: horizontal;

    display: flex;
    flex-direction: column;
}

.message-log-item {
    font-size: 0.8rem;
    animation: 1.5s ease-out flash;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

@keyframes flash {
  from {
    background-color: yellow;
  }

  to {
    background-color: inherit;
  }
}
