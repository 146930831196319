.spinning-infinite {
  animation: 1s ease-in-out infinite spin;
  display: inline-block;
  margin: 0.5rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
