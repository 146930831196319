/* https://stackoverflow.com/questions/31128349/partially-overlap-elements-using-css */
.Card-container {
  max-width: 6rem;
  flex: 1 0 0;
  overflow-x: hidden;
}
.Card-container:last-child {
  flex: 0 0 auto;
}

.selectable:hover {
  transform: translate(0, -0.3rem);
  z-index: 100;
}

.selected {
  background-color: rgb(193, 209, 255);
}

.Card {
  width: 5rem;
  height: 7rem;

  /* users will intereact with cards, and we don't want to highlight/select
  by accident when choosing them */
  user-select: none;

  flex-grow: 0;
  flex-shrink: 1;

  display: flex;
  flex-flow: column nowrap;

  border: 1px solid black;
  border-radius: 0.3rem;
}

.Card-half {
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.Card-symbol-col {
  font-size: 0.9rem;
  font-weight: bold;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.flipped {
  transform: rotate(180deg);
}

.mx03 {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.card-row {
  overflow: hidden;
  align-items: flex-start;
  margin: 0.5rem;
  resize: horizontal;
  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.2);
}
