body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 16px;
}
@media (min-width: 768px) {
  html {
    font-size: 18px; 
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 20px; 
  }
}


input {
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-col {
  display: flex;
  flex-flow: col nowrap;
}

.align-items-center {
  align-items: center;
}

.flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.shadowed {
  box-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.12);
}

ol, ul {
  display: inline-block;
  list-style-type: none;
  margin: 0.5rem;
  padding: 0rem;
}

li {
  padding: 0.2rem;
  background: white;
}
li:nth-child(odd) {
  background: rgb(225, 225, 225);
}